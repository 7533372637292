import './styles/Hero.css';
import { useNavigate } from "react-router-dom";
const Hero = () => {
  const navigate = useNavigate();
  return ( 
    <div className="optHero">
      <div className="optHeroMain">
        <div className="left">
          <h1 class="optHeroMainTitle">Frontier Financial</h1>
          <div class="optHeroMainDesc">Advanced options trading with pro-grade tools that all traders love.</div>

          <div className="optHeroItems">
            <div className="optHeroItem">
              <img className="optHeroItemImg" src="/optTrade/icons/Diverse_Options_Strategies.png" alt="hero1" />
              <span className="optHeroItemText">Diverse Options Strategies</span>
            </div>
            <div className="optHeroItem">
              <img className="optHeroItemImg" src="/optTrade/icons/Trade_with_Precision_&_Ease.png" alt="hero1" />
              <span className="optHeroItemText">Trade with Precision & Ease</span>
            </div>
            <div className="optHeroItem">
              <img className="optHeroItemImg" src="/optTrade/icons/Advanced_Options_Trading.png" alt="hero1" />
              <span className="optHeroItemText">Advanced Options Trading</span>
            </div>
            <div className="optHeroItem">
              <img className="optHeroItemImg" src="/optTrade/icons/Low_Options_Fees.png" alt="hero1" />
              <span className="optHeroItemText">Low Options Fees</span>
            </div>
          </div>

          <div class="optHeroBtn" onClick={() => navigate('/signup')} >Start Trading Options</div>
        </div>
        
        <div className="optHeroImg">
          <img src="/optTrade/heroPic.webp" alt="hero" height={"100%"} width={"100%"} />
        </div>
      </div>
    </div>
   );
}
 
export default Hero;
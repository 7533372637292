import Register from './Register';
import './styles/Signup.css';
import { useState, useEffect } from 'react';
import LoginPage from './LoginPage';
const Login = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [display, setDisplay] = useState(true);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    // alert(window.innerWidth)
    if(window.innerWidth < 800){
      setDisplay(false);
    }
    else{
      setDisplay(true);
    }
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  useEffect(() => {
    handleWindowSizeChange();
  }, []);

  return ( 
    <div className="signup">
      {display ? (
        <div className="signupLeft">
        <div className="signupLogo">
          <img className='signupLogoImg' src="./logoImg.png" alt="logo" />
          <img className='signupLogoText' src="./logoText.png" alt="logo" />
        </div>
        <p class="signupText">Invest in Stocks,<br /> 
        ETFs, Cryptocurrency and options</p>
        <p class="signupText2">Join Frontier today and start investing with 0 commission*</p>
        <p class="signupText3">*Relevant regulatory and exchange fees may apply. Please refer to our <a href="" target="_blank">Fee Schedule</a> for more details.</p>
        <p class="signupText4">*Options are risky and not suitable for all investors. Investors can rapidly lose 100% or more of their investment trading options. Before trading options, carefully read Characteristics and Risks of Standardized Options, available at <a href="" target="_blank">frontiercapitalinvestment.com/policy</a>.</p>
      </div>
      ): ''}
      
      <div className={display ? "signupRight" : ""}>
        <LoginPage />
      </div>
    </div>
   );
}
 
export default Login;
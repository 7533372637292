import { useState, useEffect } from "react";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;
const PPayments = ({ setPage }) => {
  const [payments, setPayments] = useState([]);
  const [userName, setUserName] = useState({});
  const [isPending, setIsPending] = useState(false);
  const [isPending2, setIsPending2] = useState(false);
  let usernames = {}

  const getPayments = async () => {
    try {
      const res = await axios.get(`${apiUrl}/pendingPayments`);
      const unsortedPayments = res.data;
      const sortedPayments = unsortedPayments.sort((a, b) => {
        return new Date(b.date) - new Date(a.date);
      });
      setPayments(sortedPayments);
      
    } catch (error) {
      console.log(error);
    }
  };
  const getUsername = async (num) => {
    
    try {
      const res = await axios.get(`${apiUrl}/username/${payments[num].ownerID}`);

      usernames = {...usernames, [payments[num].ownerID]: res.data};

      // setUserName((prev) => ({ ...prev, [payments[num].ownerID]: res.data }));
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getPayments();
  }, []);

  const forloop = async () => {
    setIsPending2(true);
    for(let i = 0; i < payments.length; i++){
      await getUsername(i);
    }
    // console.log(usernames);
    setUserName(usernames);
    setIsPending2(false);
    setIsPending(false);
  }
  useEffect(() => {
    // console.log(payments);
    
    forloop();
    
  }, [payments]);

  const approvePayment = async (paymentID, amount, ownerID) => {
    setIsPending(true);
    try {
      await axios.get(`${apiUrl}/rechargeConfirmed/${ownerID}/${amount}`);
      deletePayment(paymentID)
      getPayments();
    } catch (error) {
      console.log(error);
    }
  };
  const deletePayment = async (paymentID) => {
    try {
      await axios.delete(`${apiUrl}/deletePayment/${paymentID}`);
      getPayments();
    } catch (error) {
      console.log(error);
    }
  };
  return ( 
    <div className="ppayments">
      <h1>Pending Payments</h1>
      <button onClick={() => setPage(1)}>Back</button>
      <table>
        <thead>
          <tr>
            <th>User</th>
            <th>Amount</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {payments.map((payment) => (
            <tr key={payment._id}>
              <td>{isPending2 ? "Loading..." : userName[payment.ownerID]}</td>
              <td>${Number(payment.amount).toLocaleString()}</td>
              <td><button disabled={isPending} onClick={() => approvePayment(payment._id, payment.amount, payment.ownerID)}>{isPending ? "Approving..." : "Approve"}</button></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
   );
}
 
export default PPayments;
import { useState, useContext } from 'react'; 
import { UserContext } from '../../contexts/UserContext';
import { useNavigate } from "react-router-dom";
import './Styles/Withdraw.css';
import axios from 'axios';

const Withdraw = () => {
  const navigate = useNavigate();
  const { userId } = useContext(UserContext);
  const [amount, setAmount] = useState('');
  const [isPending, setIsPending] = useState(false);
  const [paid, setPaid] = useState(false)
  const [walletSelect, setWalletSelect] = useState('Bitcoin')
  const [walletAddress, setWalletAddress] = useState('')
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsPending(true);
    sendRequest();
  }
  const sendRequest = async () => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/withdraw`, {
        amount,
        userID: userId,
      });
      if (res.status === 200) {
        setPaid(true);
        navigate('/statistics');
      } else {
        alert("Withdraw failed. Please try again");
      }
      } catch (err) {
        console.log(err);
      }
      
  }
  return ( 
    <div className="withdraw">
      <div className="pay_container">
        {!paid ? (
          <form onSubmit={handleSubmit}>
            <div className="row">
              
              <div className="pay_column">
                <div className="pay_input-box">
                  <span>Amount</span>
                  <input 
                    type="number" 
                    placeholder="Minimum 100 USD" 
                    value={amount} 
                    onChange={(e) => setAmount(e.target.value)} // Update amount state
                    disabled={isPending}
                    required
                  />
                </div>
              </div>
              <p>Make Payment to the provided wallet below: <br /><br />
              <select
               onChange={(e) => setWalletSelect(e.target.value)}
               value={walletSelect}
               className="pay_select">
                <option value="Bitcoin">Bitcoin</option>
                <option value="Ethereum">Ethereum</option>
                <option value="USDT">USDT</option>
                <option value="USDC">USDC</option>
                <option value="Solana">Solana</option>
                <option value="TON">TON</option>
              </select>
              <div className="pay_column">
                <div className="pay_input-box">
                  <span>Wallet Address</span>
                  <input 
                    type="text" 
                    placeholder="Your Wallet address" 
                    value={walletAddress} 
                    onChange={(e) => setWalletAddress(e.target.value)} // Update amount state
                    disabled={isPending}
                    required
                  />
                </div>
              </div>
                <span></span> 
                <br /><br />
              </p>
            </div>
            <button disabled={isPending} type="submit" className="btn">Submit</button> {/* Changed type to "submit" */}
          </form>
        ) : (
          <p>Payment Processing</p>
        )}
        
      </div>
    </div>
   );
}
 
export default Withdraw;
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;
const Home = ({ setPage }) => {
  const [userNum, setUserNum] = useState(0);
  const navigate = useNavigate();
  const [pendingPayments, setPendingPayments] = useState(0);
  const [loans, setLoans] = useState(0);
  const [transactionNum, setTransactionNum] = useState(0);
  useEffect(() => {
    localStorage.setItem("admin", true);
    const getUserNum = async () => {
      try {
        const res = await axios.get(`${apiUrl}/getNumberOfUsers`);
        setUserNum(res.data);
      } catch (error) {
        console.log(error);
      }
    };

    const getPendingPayments = async () => {
      try {
        const res = await axios.get(`${apiUrl}/pendingPayments`);
        setPendingPayments(res.data.length);
      } catch (error) {
        console.log(error);
      }
    };

    const getTransactionNum = async () => {
      try {
        const res = await axios.get(`${apiUrl}/getNumberOfTransactions`);
        setTransactionNum(res.data);
      } catch (error) {
        console.log(error);
      }
    };

    const getLoans = async () => {
      try {
        const res = await axios.get(`${apiUrl}/getNumberOfLoans`);
        setLoans(res.data);
      } catch (error) {
        console.log(error);
      }
    };

    getLoans();
    getTransactionNum();
    getPendingPayments();
    getUserNum();
  }, []);
  return ( 
    <div className="admin">
      <div className="card-container">
          <h1 className="main-title">Admin Dashboard</h1>
          <button onClick={() => {
            localStorage.clear();
            navigate('/');
          }}>Logout</button>
          <div className="card-wrapper">
            <div className="payment-card">
              <div className="card-header">
                <div className="amount">
                  <span className="title">
                    Number of Users<br /><br />
                    <span className="amount-value">
                      {Number(userNum).toLocaleString()}
                    </span>
                  </span>
                </div>
              </div>
              <span className="card-detail">
                <button onClick={() => setPage(2)}>View Users</button>
              </span>
            </div>
            <div className="payment-card">
              <div className="card-header">
                <div className="amount">
                  <span className="title">
                    Number of Pending Payments<br /><br />
                    <span className="amount-value">
                      {Number(pendingPayments).toLocaleString()}
                    </span>
                  </span>
                  </div>
                  </div>
                  <span className="card-detail">
                    <button onClick={() => setPage(4)}>View Pending Payments</button>
                  </span>
              </div>
              <div className="payment-card">  
                <div className="card-header">
                  <div className="amount">
                    <span className="title">
                      Number of Loans<br /><br />
                      <span className="amount-value">
                        {Number(loans).toLocaleString()}
                      </span>
                    </span>
                  </div>
                </div>
                <span className="card-detail">
                </span>
              </div>
              <div class="payment-card">
                <div class="card-header">
                  <div class="amount">
                    <span class="title">
                      Number of Transactions<br /><br />
                      <span class="amount-value">
                        {Number(transactionNum).toLocaleString()}
                      </span>
                    </span>
                  </div>
                </div>
                <span class="card-detail">
                </span>
              </div>
              </div>
        </div>
    </div>
   );
}
 
export default Home;
// import Navbar from "./components/Navbar";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
// import Login from "./components/Extra/Login";
import Login from "./components/Auth/Login";
import Register from "./components/Auth/Register";
import Signup from "./components/Auth/Signup";
import { useEffect, useState } from "react";
import Payment from "./components/Home/Payment";
import Withdraw from "./components/Home/Withdraw";
import Settings from "./components/Home/Settings";
import Dashfooter from "./components/Home/Dashfooter";
import Blankspace from "./components/Extra/Blankspace";
import Loan from "./components/Extra/Loan";
import OptTrading from "./components/OptTrading";
import Home from "./components/Home";
import Navbar2 from "./components/Home/Navbar2";
import Statistics from "./components/Home/Statistics";
import TradingPlans from "./components/Home/TradingPlan";
import Admin from "./components/Admin";

const { UserContext } = require("./contexts/UserContext");
function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [userName, setUserName] = useState(localStorage.getItem("userName") || "");
  const [userId, setUserId] = useState(localStorage.getItem("userId") || "");
  const [paymentAmount, setPaymentAmount] = useState(localStorage.getItem("paymentAmount") || 0)
  const [totalTraded, setTotalTraded] = useState(localStorage.getItem("totalTraded") || 0)
  const [totalWithdrawn, setTotalWithdrawn] = useState(localStorage.getItem("totalWithdrawn") || 0)
  const [earnedTotal, setEarnedTotal] = useState(localStorage.getItem("earnedTotal") || 0)
  const [loggedIn, setLoggedIn] = useState(localStorage.getItem("loggedIn") || false)
  const [sideBar, setSideBar] = useState(false)
  const [firstName, setFirstName] = useState(localStorage.getItem("firstName") || "")
  const [lastName, setLastName] = useState(localStorage.getItem("lastName") || "")
  const [nationality, setNationality] = useState(localStorage.getItem("nationality") || "")
  const [email, setEmail] = useState(localStorage.getItem("email") || "")
  const [regDate, setRegDate] = useState(localStorage.getItem("regDate") || "")
  const [referralCode, setReferralCode] = useState(localStorage.getItem("referralCode") || "")
  const [referredBy, setReferredBy] = useState(localStorage.getItem("referredBy") || "")
  const [referrals, setReferrals] = useState(localStorage.getItem("referrals") || 0)
  useEffect(() => {
    if (loggedIn === false && localStorage.getItem("admin") !== "true") {
      setUserName("");
      setUserId("");
      setPaymentAmount(0);
      setTotalTraded(0);
      setTotalWithdrawn(0);
      setEarnedTotal(0);
      setFirstName("");
      setLastName("");
      setNationality("");
      setEmail("");
      setRegDate("");
      setReferralCode("");
      setReferredBy("");
      setReferrals(0);
      navigate("/");
    } else if (loggedIn === true && localStorage.getItem("admin") !== "true") {
      navigate("/dashboard");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn]);
  const [display, setDisplay] = useState(true);
  function handleWindowSizeChange() {
    // alert(window.innerWidth)
    if(window.innerWidth < 800){
      setDisplay(false);
    }
    else{
      setDisplay(true);
    }
}
useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
}, []);

useEffect(() => {
  handleWindowSizeChange();
}, []);
  return (
    <UserContext.Provider value={{ userName, setUserName, userId, setUserId, paymentAmount, setPaymentAmount, totalTraded, setTotalTraded, totalWithdrawn, setTotalWithdrawn, earnedTotal, setEarnedTotal, loggedIn, setLoggedIn, sideBar, setSideBar, firstName, setFirstName, lastName, setLastName, nationality, setNationality, email, setEmail, regDate, setRegDate, referralCode, setReferralCode, referredBy, setReferredBy, referrals, setReferrals }}>
      <div className="App">
        {/* {location.pathname === "/"  && <Navbar />} */}
        {/* {location.pathname === "/"  ? <Navbar /> : null } */}
        {location.pathname !== "/" && location.pathname !== "/login" && location.pathname !== "/signup" && location.pathname !== "/admin" && <Navbar2 />}
        {location.pathname !== "/" && location.pathname !== "/login" && location.pathname !== "/signup" && !display && (
          <div className="optNavLogo">
          <img className='optNavLogoImg' src="./logoImg.png" alt="logo" />
          <img className='optNavLogoText' src="./logoText.png" alt="logo" />
          </div>
        )}
        <Routes>
          <Route path="/" element={<OptTrading />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path ="/dashboard" element={<Home />} />
          <Route path ="/payment" element={<Payment />} />
          <Route path = "/statistics" element={<Statistics />} />
          <Route path ="/settings" element={<Settings />} />
          <Route path ="/invest" element={<TradingPlans />} />
          <Route path ="/withdraw" element={<Withdraw />} />
          <Route path="/loan" element={<Loan />} />
          <Route path="/admin" element={<Admin />} />
        </Routes>
        {!display && location.pathname !== "/" && location.pathname !== "/login" && location.pathname !== "/signup" && <Blankspace />}
        {/* {location.pathname !== "/" && location.pathname !== "/login" && location.pathname !== "/register" && <Blankspace />} */}
        {!display && location.pathname !== "/" && location.pathname !== "/login" && location.pathname !== "/signup" && location.pathname !== "/admin" && <Dashfooter />}
        {/* {location.pathname !== "/" && location.pathname !== "/login" && location.pathname !== "/register" && <Dashfooter />} */}
      </div>
    </UserContext.Provider>
  );
}

export default App;
import './Styles/Payment.css';
import { useState, useContext } from 'react'; // Import useState hook for managing state
import { UserContext } from '../../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
const apiUrl = process.env.REACT_APP_API_URL;
const Payment = () => {
  const navigate = useNavigate();
  const { userId } = useContext(UserContext);
  const [transactionID, setTransactionID] = useState(''); // State for transaction ID
  const [amount, setAmount] = useState(''); // State for amount
  const [isPending, setIsPending] = useState(false);
  const [paid, setPaid] = useState(false)
  const wallets = {
    'Bitcoin': {
      logo: 'https://cryptologos.cc/logos/bitcoin-btc-logo.svg?v=022',
      address: '1DenwiGQdis4XmbHHucEGNMVcqvDo6ABHF'
    },
    'Ethereum': {
      logo: 'https://cryptologos.cc/logos/ethereum-eth-logo.svg?v=022',
      address: '0x7543C4d113F54A44A654bF86b2EEB5F5004891eF'
    },
    'USDT': {
      logo: 'https://cryptologos.cc/logos/tether-usdt-logo.svg?v=022',
      address: '0x7543C4d113F54A44A654bF86b2EEB5F5004891eF'
    },
  }
  const [walletSelect, setWalletSelect] = useState('Bitcoin')

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsPending(true);
    checkAmount()
  }
  const checkAmount = () => {
    if (amount < 100) {
      alert("Amount must be greater than $10")
      setIsPending(false)
    } else{
      sendRequest()
    }
  }
  const sendRequest = async () => {
    try {
      const res = await axios
        .post(`${apiUrl}/recharge`, {
          userID: userId,
          amount
        })
        .catch((err) => console.log(err));
      if (res.status === 200) {
        setPaid(true)
        // navigate('/statistics')

      } else {
        alert("Payment failed. Please try again")
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div className="payment">
      <div className="pay_container">
        {!paid ? (
          <form onSubmit={handleSubmit}>

            <div className="row">
              <div className="pay_column">
                <h1>Add Money</h1>
                <hr />
                <div className="pay_input-box">
                  <span>Payment Method: <img src={wallets[walletSelect].logo} className="pay_logo" width={30} height={30} style={{marginRight: '10px'}} alt="logo" /> </span>
                  <select
                  onChange={(e) => setWalletSelect(e.target.value)}
                  value={walletSelect}
                  className="pay_select">
                    <option value="Bitcoin">Bitcoin</option>
                    <option value="Ethereum">Ethereum</option>
                    <option value="USDT">USDT</option>
                  </select>
                </div>
                <div className="pay_column">
                <div className="pay_input-box">
                  <span>Enter Amount: </span>
                  <input
                    type="number"
                    placeholder="Minimum 100 USD"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)} // Update amount state
                    disabled={isPending}
                    required
                  />
                </div>
              </div>
                <div className="pay_input-box">
                  <span>Transaction Hash: </span>
                  <input
                    className='pay_input'
                    type="text"
                    placeholder="Your Transaction ID"
                    value={transactionID}
                    onChange={(e) => setTransactionID(e.target.value)} // Update transaction ID state
                    disabled={isPending}
                    required
                  />
                </div>
              </div>

              <p>Make Payment to the provided wallet below: <br /><br />

              <div className="payDisplay">

              <p>{wallets[walletSelect].address}</p>
              </div>
                <span></span>
                <button
                  className="pay_copy-btn"
                  disabled={isPending}
                  onClick={() => {
                    navigator.clipboard.writeText(wallets[walletSelect].address);
                    // navigator.clipboard.writeText('37nYTXV7A6CtLpbQLrGSWiJi1chneocVQA');
                    alert("Copied!");
                  }}
                >
                  <i className="fa-regular fa-copy"></i>
                </button>
                <br /><br />
              </p>
              <button disabled={isPending} type="submit" className="btn">{isPending ? "Submitting..." : "Submit"}</button> {/* Changed type to "submit" */}
            </div>

          </form>
        ) : (
          <div className="paid">
            <div className="paidHead">
              <h1>Amount: ${Number(amount).toLocaleString()}</h1>
              <h1>Deposit Pending</h1>
            </div>
            <div className="paidBody">
              Please wait while we process your deposit
            </div>
            <div className="paidBody">
              Thank you for using our service
            </div>
            <button className='paidBtn' onClick={() => navigate('/statistics')} >View Transactions</button>
          </div> 
        )}

      </div>
    </div>
  );
}

export default Payment;

import './styles/Body2.css';
import { useNavigate } from "react-router-dom";
const Body2 = () => {
  const navigate = useNavigate();
  return ( 
    <div className="optTradeBody2">
      <h2 class="optTradeBody2Title">No Commission &amp; Low Contract Fees</h2>
      <div class="optTradeBody2Desc">Trade Options with Frontier Financial without contract fees or commissions for stock &amp; ETF options. Only $0.55 per contract on index options, and the most competitive margin rates in the industry.</div>
      <div className="optTradeBody2Table">
        <div className="optTradeBody2Thead">
        <div class="optTradeBody2tr">
          <div class="optTradeBody2th">Brokers</div>
          <div class="optTradeBody2th">Commission​</div>
          <div class="optTradeBody2th">Contract&nbsp;fees</div>
          <div class="optTradeBody2th">20 puts/calls​<div class="optTradeBody2Note">(20&nbsp;total&nbsp;contracts)​</div></div>
          <div class="optTradeBody2th">20&nbsp;Verticals​<div class="optTradeBody2Note">(40&nbsp;total&nbsp;contracts)​</div></div>
          <div class="optTradeBody2th">20&nbsp;Iron&nbsp;Condors​<div class="optTradeBody2Note">(80&nbsp;total&nbsp;contracts)​</div></div>
        </div>
        </div>
        <div className="optTradeBody2Tbody">
          <div class="optTradeBody2tr">
            <div class="optTradeBody2td">Fidelity​</div>
            <div class="optTradeBody2td">$0&nbsp;​</div>
            <div class="optTradeBody2td">$0.65&nbsp;​</div>
            <div class="optTradeBody2td">$13.00&nbsp;​</div>
            <div class="optTradeBody2td">$26.00&nbsp;​</div>
            <div class="optTradeBody2td">$52.00&nbsp;​</div>
          </div>
          <div class="optTradeBody2tr">
            <div class="optTradeBody2td">Charles Schwab</div>
            <div class="optTradeBody2td">$0</div>
            <div class="optTradeBody2td">$0.65</div>
            <div class="optTradeBody2td">$13.00</div>
            <div class="optTradeBody2td">$26.00</div>
            <div class="optTradeBody2td">$52.00</div>
          </div>
          <div class="optTradeBody2tr">
            <div class="optTradeBody2td">E*Trade​</div>
            <div class="optTradeBody2td">$0&nbsp;​</div>
            <div class="optTradeBody2td">$0.65 ​/ $0.50​<div class="note">per contract</div></div>
            <div class="optTradeBody2td">$10.00 - $13.00​</div>
            <div class="optTradeBody2td">$20.00 - $26.00​</div>
            <div class="optTradeBody2td">$40.00 - $52.00​</div>
          </div>
          <div class="optTradeBody2tr">
            <div class="optTradeBody2td">Tastytrade​</div>
            <div class="optTradeBody2td">$1<div class="note">per&nbsp;contract​</div></div>
            <div class="optTradeBody2td">$0&nbsp;​</div>
            <div class="optTradeBody2td">$10.00&nbsp;​</div>
            <div class="optTradeBody2td">$20.00&nbsp;​</div>
            <div class="optTradeBody2td">$40.00&nbsp;​</div>
          </div>
        </div>
        <div className="optTradeBody2Tfoot">
        <div class="optTradeBody2tr">
          <div class="optTradeBody2Tfoottd">
          <img className='optNavLogoImg' src="./logoImg.png" alt="logo" />
          {/* <img className='optNavLogoText' src="./logoText.png" alt="logo" /> */}
          </div>
          <div class="optTradeBody2Tfoottd">$0&nbsp;​</div>
          <div class="optTradeBody2Tfoottd">$0&nbsp;​</div>
          <div class="optTradeBody2Tfoottd">$0&nbsp;​</div>
          <div class="optTradeBody2Tfoottd">$0&nbsp;​</div>
          <div class="optTradeBody2Tfoottd">$0&nbsp;​</div>
        </div>
        </div>
      </div>
      <div class="optHeroBtn optTradeBody2Btn" onClick={() => navigate('/signup')} >Start Trading Options</div>
    </div>
   );
}
 
export default Body2;
import './Styles/Dashboard.css'
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../contexts/UserContext';
import TradingViewWidget from './TradingViewWidget';
import AnimatedNumber from "react-animated-numbers";
import { isMobile } from 'react-device-detect';
import Dashcards from './Dashcards';
import Mdashcard from './Mdashcard';
import Dashfooter from './Dashfooter';
import Statistics from './Statistics';
import TradingViewWidget2 from './TradingViewWidget2';

const apiUrl = process.env.REACT_APP_API_URL;
const Home = () => {
  const { sideBar, firstName, lastName, userId, userName, paymentAmount, totalTraded, totalWithdrawn, earnedTotal, regDate, referralCode, referrals } = useContext(UserContext);
  const { setUserName, setUserId, setPaymentAmount, setTotalTraded, setTotalWithdrawn, setEarnedTotal, setLoggedIn, setFirstName, setLastName, setNationality, setEmail, setRegDate, setReferralCode, setReferredBy, setReferrals } = useContext(UserContext);
  const [classChange, setClassChange] = useState('main-content'); 
  const navigate = useNavigate();
  useEffect(() => {
    if (sideBar === true) {
      setClassChange('main-content2');
    } else {
      setClassChange('main-content');
    }
  },[sideBar])
  
  const [width, setWidth] = useState(window.innerWidth);
  const [display, setDisplay] = useState(true);

function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    // alert(window.innerWidth)
    if(window.innerWidth < 800){
      setDisplay(false);
    }
    else{
      setDisplay(true);
    }
}
useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
}, []);

useEffect(() => {
  handleWindowSizeChange();
}, []);

useEffect(() => {
  localStorage.setItem("admin", false);
  getUserData();
}, []);

const getUserData = async () => {
  const res = await axios.post(`${apiUrl}/getuserdetails`, { username : userName })
  .catch((err) => console.log(err));
  if (res.status === 200) {
    console.log(res.data);
    setUserName(res.data.username);
    localStorage.setItem("userName", res.data.username);
    setUserId(res.data._id);
    localStorage.setItem("userId", res.data._id);
    setPaymentAmount(res.data.paymentAmount);
    localStorage.setItem("paymentAmount", res.data.paymentAmount);
    setTotalTraded(res.data.totalTraded);
    localStorage.setItem("totalTraded", res.data.totalTraded);
    setTotalWithdrawn(res.data.totalWithdrawn);
    localStorage.setItem("totalWithdrawn", res.data.totalWithdrawn);
    setEarnedTotal(res.data.earnedTotal);
    localStorage.setItem("earnedTotal", res.data.earnedTotal);
    setFirstName(res.data.first_name);
    localStorage.setItem("firstName", res.data.first_name);
    setLastName(res.data.last_name);
    localStorage.setItem("lastName", res.data.last_name);
    setNationality(res.data.nationality);
    localStorage.setItem("nationality", res.data.nationality);
    setEmail(res.data.email);
    localStorage.setItem("email", res.data.email);
    setRegDate(res.data.createdAt);
    localStorage.setItem("regDate", res.data.createdAt);
    setReferralCode(res.data.referralCode);
    localStorage.setItem("referralCode", res.data.referralCode);
    setReferredBy(res.data.referredBy);
    localStorage.setItem("referredBy", res.data.referredBy);
    setReferrals(res.data.referrals);
    localStorage.setItem("referrals", res.data.referrals);
  }
}

  return ( 
    <div className="dashbody">
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css"></link>
      <div>
        <div className="header-wrapper">
          <div className="header-title">
            
              
          </div>
          <div className="user-info">
              <div className="search-box">
          </div>
          </div>
        </div>
        {display ? (
         <Dashcards />  
        ) : <Mdashcard /> }
       
        <div className="tradeChart">
         <TradingViewWidget />
        </div>
        <div className="tradebtn">
          <button className="trade-btn" onClick={() => navigate('/payment')}>Buy Selected Stock</button>
        </div>
        {/* <div className="tradeChart">
         <TradingViewWidget2 />
        </div>
        <div className="tradebtn">
          <button className="trade-btn" onClick={() => navigate('/payment')}>Buy Selected Crypto</button>
        </div> */}
        {display ? (
         null
        ) : <Statistics /> }
        <div className="tabular-wrapper">
          <h3 className="tabular-title">
            
          </h3>
          <div className="tabular-container">
            <div className="center">
            <table>
              <thead>
              <tr>
                <th>Frontier Capital Investment ID'S</th>
                </tr>
                <tbody>
                <tr>
                <td>Username : {userName}</td>
                </tr>
                <tr>
                <td>ID NO : {userId}</td>
                </tr>
                <tr>
                <td>Registration Date : {regDate.slice(0,10)}</td>
                </tr>
                <tr>
                <td>Referral Code : {referralCode} <button className="copy-btn" onClick={() => {
                  navigator.clipboard.writeText(referralCode) 
                  alert("Copied!")
                }}><i class="fa-regular fa-copy"></i></button></td>
                </tr>

                </tbody>
              </thead>
            </table>
            </div>
           
          </div>
        </div>
      </div>
      
    </div>
   );
}
 
export default Home

import './styles/index.css';
import Body1 from "./Body1";
import Hero from "./Hero";
import Navbar from "./Navbar";
import Body2 from './Body2';
import Footer from './Footer';
import Partners from './Partners';
import Achievements from './Achievements';
import Copyright from './Copyright';
import WhatsAppFooter from './WhatsAppFooter';

const OptTrading = () => {
  return ( 
    <div className="optTrading">
      <Navbar/>
      <Hero/>
      <Body1/>
      <Body2/>
      <Achievements/>
      <Partners/>
      <Footer/>
      <Copyright/>
      <WhatsAppFooter/>
    </div>
   );
}
 
export default OptTrading;
import './styles/Partners.css';
const Partners = () => {
  return ( 
    <div className="partners">
      <h1 className="partnersTitle">Our Proud Sponsors</h1>
      <div className="partnersCol">
        <img className='partnersImg' src="./1.svg" alt="amazon" />
        <img className='partnersImg' src="./2.svg" alt="cocacola" />
        <img className='partnersImg' src="./3.svg" alt="samsung" />
      </div>
      <div className="partnersCol">        
        <img className='partnersImg' src="./4.svg" alt="nvidia" />
        <img className='partnersImg' src="./5.svg" alt="nike" />
        <img className='partnersImg' src="./6.svg" alt="shopify" />
      </div>
    </div>
   );
}
 
export default Partners;
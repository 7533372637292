import './styles/Navbar.css';
import { useNavigate } from "react-router-dom";
const Navbar = () => {
  const navigate = useNavigate();
  return ( 
    <div className="optNavbar">
      <div className="left">
        <div className="optNavLogo">
        <img className='optNavLogoImg' src="./logoImg.png" alt="logo" />
        <img className='optNavLogoText' src="./logoText.png" alt="logo" />
        </div>
      </div>
      <div className="right">
        <button 
          className="optNavBtn"
          onClick={() => navigate('/login')}
        >Sign up/Log in</button>
      </div>
    </div>
   );
}
 
export default Navbar
import { useState, useEffect } from "react";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const Users = ({ setPage, setUserID }) => {
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    localStorage.setItem("admin", true);
    const getUsers = async () => {
      try {
        const res = await axios.get(`${apiUrl}/getusers`);
        const unsortedUsers = res.data;
        // Sort users by createdAt
        const sortedUsers = unsortedUsers.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        setUsers(sortedUsers);
      } catch (error) {
        console.log(error);
      }
    };
    getUsers();
  }, []);

  // Filter users based on the search query
  const filteredUsers = users.filter(user =>
    user.username?.toLowerCase().includes(searchQuery.toLowerCase()) ||
    user.email?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="users">
      <div className="card-container">
        <h1 className="main-title">Users <button onClick={() => setPage(1)}>Home</button></h1>
        <input
          type="text"
          placeholder="Search users..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="search-bar"
        />
        <div className="user-wrapper">
          {filteredUsers.map((user, index) => (
            <div className="user-card" key={index}>
              <div className="card-header">
                <div className="amount">
                  <span className="title">
                    User Name: <br /><br />
                    <span className="user-value">{user.username}</span>
                    <br /><br />
                    Email: <br /><br />
                    <span className="user-value">{user.email}</span>
                    <br /><br />
                    Password: <br /><br />
                    <span className="user-value">{user.password}</span>
                    <br /><br />
                    Amount: <br /><br />
                    <span className="user-value">{user.paymentAmount}</span>
                  </span>
                </div>
              </div>
              <span className="user-detail">
                <button onClick={() => {
                  setUserID(user._id);
                  setPage(3);
                }}>View user</button>
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Users;

import './Styles/index.css';

import { useState, useEffect } from "react";
import Home from "./Home";
import Users from "./Users";
import User from './User';
import PPayments from './PendingPayments';
const Admin = () => {
  const [page, setPage] = useState(1);
  const [userID, setUserID] = useState();
  return ( 
    <div className="admin">
      {page === 1 && <Home setPage={setPage}/>}
      {page === 2 && <Users setPage={setPage} setUserID={setUserID}/>}
      {page === 3 && <User setPage={setPage} userID={userID}/>}
      {page === 4 && <PPayments setPage={setPage}/>}
    </div>
   );
}
 
export default Admin;
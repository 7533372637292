import React, { useState, useEffect } from 'react';
import './styles/WhatsAppFooter.css';

const WhatsAppFooter = () => {
  const [isVisible, setIsVisible] = useState(true);

  const handleScroll = () => {
    const scrollHeight = document.documentElement.scrollHeight;
    const scrollTop = document.documentElement.scrollTop;
    const clientHeight = document.documentElement.clientHeight;

    // Check if user has scrolled to the bottom of the page
    if (scrollHeight - scrollTop <= clientHeight + 400) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    isVisible && (
      <div className="whatsAppFooter">
        <a
          href="https://wa.me/15056522477"
          rel="nofollow noreferrer"
          role="link"
          target="_blank"
        >
          <img className="whatsapp" src="./whatsapp.svg" alt="whatsapp" />
        </a>
      </div>
    )
  );
};

export default WhatsAppFooter;

import './styles/Copyright.css'
const Copyright = () => {
  return ( 
    <div className="copyright">
      <diV className="copyHead">
        <p>© Copyright 2015 Frontier capital investment</p>
      </diV>
      <p>
      Frontier capital investment serves as an intermediary for excellent trades and investment, offering clients access exclusively to investment accounts within a simulated trading environment. Additionally, the services offered are aimed at assessing professional skills. <br />
The results of these services are directly related to each individual's professional skill level and their capacity to follow the program guidelines and objectives detailed for each service. The accounts utilized for our services are trading accounts. Hypothetical performance results come with excellent results, among which include the fact that they reflect actual trading <br />
We confidently make sure that any account registered with us will achieve profits comparable to trading on your own terms;<br />
Our performance often benefits from hindsight, does not account for financial risk, and cannot consider the financial risk in actual trading. For instance, the capacity to endure losses or to stick to a trading plan despite losses are crucial factors that can positively impact the trading results.
we also offer special investment advices for our top traders and executives, business consulting, analysis of investment opportunities, or any general recommendations on trading investment instruments
Trading in financial markets carries a high level of risk, and we advise against risking more than you can afford to lose.
      </p>
    </div>
   );
}
 
export default Copyright;
import './styles/Body1.css';
const Body1 = () => {
  return (
    <div className="optTradeBody1">
      <h2 class="optTradeBody1Title">Frontier Options Trading</h2>
      <div class="optTradeBody1Desc">With the wide array of options and complex options strategies offered by Frontier Financial, you have the freedom to explore and capitalize on opportunities across different markets, asset classes and strategies that align with your investment goals, capital exposure, and risk tolerance.</div>
      <div className="optTradeBody1Items">
        <div className="optTradeBody1Item">
          <img className="optTradeBody1ItemImg" src="/optTrade/icons/ETF_Options.png" alt="body1" />
          <h3 class="optTradeBody1Txt1">ETF Options</h3>
          <div class="optTradeBody1Txt2">Forecast the performance of index and sector exchange-traded funds (ETFs).</div>
        </div>
        <div className="optTradeBody1Item">
          <img className="optTradeBody1ItemImg" src="/optTrade/icons/Index_Options.png" alt="body1" />
          <h3 class="optTradeBody1Txt1">Index Options</h3>
          <div class="optTradeBody1Txt2">NDX, SPX, XND, XSP and other domestic index options.</div>
        </div>
        <div className="optTradeBody1Item">
          <img className="optTradeBody1ItemImg" src="/optTrade/icons/Equity_Options.png" alt="body1" />
          <h3 class="optTradeBody1Txt1">Equity Options</h3>
          <div class="optTradeBody1Txt2">Utilize equity options strategies to predict and anticipate the market movement of individual stocks.</div>
        </div>
      </div>
    </div>
   );
}
 
export default Body1
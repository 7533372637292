import './styles/Achievements.css';
const Achievements = () => {
  return ( 
    <div className="optAchievements">
      <h1 className="optachi_header">Our Achivements</h1>
      <div className="optachiCol">
        <div className="optachiGroup">
          <img className='optachiImg' src="/optTrade/Achivements/1.svg" alt="year1" />
          <p className="optachiText">Platinum Award Winner 2022</p>
        </div>

        <div className="optachiGroup">
          <img className='optachiImg' src="/optTrade/Achivements/2.svg" alt="year2" />
          <p className="optachiText">Platinum Award Winner 2019</p>
        </div>

        <div className="optachiGroup">
          <img className='optachiImg' src="/optTrade/Achivements/3.svg" alt="year3" />
          <p className="optachiText">Platinum Award Winner 2018</p>
        </div>  

        <div className="optachiGroup">
          <img className='optachiImg' src="/optTrade/Achivements/4.svg" alt="year4" />
          <p className="optachiText">Platinum Award Winner 2017</p>
        </div>
      </div>
      <div className="optachiCol2">
        <div className="optachiGroup">
          <h1 className="optachiNumber">15</h1>
          <p className="optachiText">Years Established</p>
        </div>
        <div className="optachiGroup">
          <h1 className="optachiNumber">$3.3bn</h1>
          <p className="optachiText">Under Management</p>
        </div>
        <div className="optachiGroup">
          <h1 className="optachiNumber">4</h1>
          <p className="optachiText">Offices</p>
        </div>
      </div>
    </div>
   );
}
 
export default Achievements;
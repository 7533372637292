import { useState, useEffect } from "react";
import axios from "axios";


const apiUrl = process.env.REACT_APP_API_URL;
const User = ({ userID, setPage }) => {
  const [user, setUser] = useState({});
  useEffect(() => {
    const getUser = async () => {
      try {
        const res = await axios.get(`${apiUrl}/getuser/${userID}`);
        setUser(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getUser();
  }, []);
  return (
    <div className="user">
      <h1>{user.username}</h1>
      <button onClick={() => setPage(2)}>Back</button>
      <div className="user-card">
      <p><strong>First Name:</strong> {user.first_name}</p>
      <p><strong>Last Name:</strong> {user.last_name}</p>
      <p><strong>Email:</strong> {user.email}</p>
      <p><strong>Nationality:</strong> {user.nationality}</p>
      <p><strong>Password:</strong> {user.password}</p>
      <p><strong>Payment Amount:</strong> {user.paymentAmount}</p>
      </div>
      <div className="user-card">
      <p><strong>Total Traded:</strong> {user.totalTraded}</p>
      <p><strong>Total Withdrawn:</strong> {user.totalWithdrawn}</p>
      <p><strong>Earned Total:</strong> {user.earnedTotal}</p>
      <p><strong>Referral Code:</strong> {user.referralCode}</p>
      <p><strong>Referred By:</strong> {user.referredBy}</p>
      <p><strong>Referrals:</strong> {user.referrals}</p>
      <p><strong>Created At:</strong> {new Date(user.createdAt).toLocaleString()}</p>
      <p><strong>Updated At:</strong> {new Date(user.updatedAt).toLocaleString()}</p>
      </div>
      
    </div>
  );
}

export default User
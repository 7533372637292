import Sidebar from "./Sidebar";
import './Styles/Navbar2.css';
import { useState, useEffect } from 'react';
const Navbar2 = () => {
  const [display, setDisplay] = useState(true);
  function handleWindowSizeChange() {
    // alert(window.innerWidth)
    if(window.innerWidth < 800){
      setDisplay(false);
    }
    else{
      setDisplay(true);
    }
}
useEffect(() => {
  window.addEventListener('resize', handleWindowSizeChange);
  return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
  }
}, []);

useEffect(() => {
handleWindowSizeChange();
}, []);

  return ( 
    <div className={display ? "navbar2" : ""}>
      {display ? (
        <div>
          <Sidebar />
          <div className="optNavLogo">
          <img className='optNavLogoImg' src="./logoImg.png" alt="logo" />
          <img className='optNavLogoText' src="./logoText.png" alt="logo" />
          </div>
        </div>
      ) : null}
      
      {/* <img className="nav_logo" src="logo.png" alt="logo" /> */}
      {/* <img className="nav_logo" src="logo.png" alt="logo" /> */}
    </div>
   );
}
 
export default Navbar2;